import api from './api';

const service = {

  // ユーザー検索
  async searchUsers(params) {
    return await api.get(`/users`, { params });
  },
  async findUser(params) {
    return await api.get(`/users/${params.user_id}`);
  },
  // 新規ユーザー仮登録
  async createUser(params) {
    return await api.post(`/users`, params);
  },
  // ユーザ更新
  async updateUser(sendParams) {
    return await api.put(`/users/${sendParams.user_id}`, sendParams);
  },
  // ユーザ削除
  async deleteUser(params) {
    return await api.delete(`/users/${params.user_id}`);
  },
  // パスワード変更
  async updatePassword(user_id, params) {
    return await api.put(`/users/${user_id}/password`, params);
  },
  // 発電所（リソース件数）検索
  async searchPplantBelong(params) {
    return await api.get(`/users/aggregators/${params.aggregator_id}/resources/select`, { params });
  },
  // 契約検索
  async searchContractBelong(params) {
    return await api.get(`/users/aggregators/${params.aggregator_id}/contract/select`, { params });
  },
  
  // ユーザー通知設定検索
  async searchNotice(params) {
    return await api.get(`/users/notice`, { params });
  },
  // ユーザー通知設定更新
  async updateNotice(params) {
    return await api.put(`/users/notice`, params);
  },

  // アグリゲータ照会
  async findMarketAggregator(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}`);
  },
  // スポット市場売買上下限デフォルト値照会
  async findSpotMarketTradingThresholdDefault(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/spot_market/trading/threshold/default`);
  },
  // 時間前市場売買上下限デフォルト値照会
  async findSameDayMarketTradingThresholdDefault(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/same_day_market/trading/threshold/default`);
  },
  // スポット市場入札価格照会
  async findSpotMarketBidPrice(aggregator_id, area_id) {
    return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/spot_market/bid_price`);
  },
  // JEPXアカウント 更新
  async updateJepxAccount(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/jepx_account`, params);
  },
  // 相対取引用JEPXアカウント登録
  async createJepxAccountNegotiation(params) {
    return await api.post(`/aggregators/${params.aggregator_id}/jepx_account_negotiation`, params);
  },
  // 相対取引用JEPXアカウント更新
  async updateJepxAccountNegotiation(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/jepx_account_negotiation`, params);
  },
  // 相対取引用JEPXアカウント削除
  async deleteJepxAccountNegotiation(aggregator_id, params) {
    return await api.delete(`/aggregators/${aggregator_id}/power_trader_data_kind_numbers/${params.power_trader_data_kind_number}/jepx_account_negotiation`);
  },
  // 発電所別取引用JEPXアカウント登録
  async createJepxAccountPplant(params) {
    return await api.post(`/aggregators/${params.aggregator_id}/jepx_account_pplant`, params);
  },
  // 発電所別取引用JEPXアカウント更新
  async updateJepxAccountPplant(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/jepx_account_pplant`, params);
  },
  // 発電所別取引用JEPXアカウント削除
  async deleteJepxAccountPplant(aggregator_id, params) {
    return await api.delete(`/aggregators/${aggregator_id}/power_trader_data_kind_numbers/${params.power_trader_data_kind_number}/jepx_account_pplant`);
  },
  // アグリゲータ基本設定 権限照会
  async checkAggregatorBasicSettings() {
    return await api.get(`/basic_settings_display`);
  },
  // 分割番号設定登録
  async createPlanSplitNumberSetting(aggregator_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/split_number_settings`, params);
  },
  // 分割番号設定更新
  async updatePlanSplitNumberSetting(aggregator_id, params) {
    return await api.put(`/aggregators/${aggregator_id}/split_number_settings`, params);
  },
  // 分割番号設定削除
  async deletePlanSplitNumberSetting(aggregator_id, area_id) {
    return await api.delete(`/aggregators/${aggregator_id}/areas/${area_id}/split_number_settings`);
  },
  // 手数料 更新
  async updateFee(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/commission`, params);
  },
  // スポット市場売買上下限デフォルト値 更新
  async updateUserSpotMarketDefault(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/spot_market/trading/threshold/default`, params);
  },
  // 時間前市場売買上下限デフォルト値 更新
  async updateUserSameDayMarketDefault(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/same_day_market/trading/threshold/default`, params);
  },
  // スポット市場入札価格 更新
  async updateUserSpotMarketBidPrice(params, aggregator_id, area_id) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/spot_market/bid_price`, params);
  },

  // 相対取引先 検索
  async searchRelativeTrading(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/negotiation/customers`);
  },
  // 相対取引先 登録
  async createRelativeTrading(aggregator_id, area_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/areas/${area_id}/negotiation/customers`, params);
  },
  // 相対取引先 更新
  async updateRelativeTrading(aggregator_id, area_id, params) {
    return await api.put(`/aggregators/${aggregator_id}/areas/${area_id}/negotiation/customers/${params.customer_code}`, params);
  },
  // 相対取引先 削除
  async deleteRelativeTrading(aggregator_id, area_id, customer_code) {
    return await api.delete(`/aggregators/${aggregator_id}/areas/${area_id}/negotiation/customers/${customer_code}`);
  },
  // 相対取引先 運用設定変更
  async updateRelativeTradingOperateFlg(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/negotiation/customers/${params.customer_code}/operate_flg`, params);
  },
  // 相対取引先 発電所 検索
  async searchRelativeTradingSite(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/negotiation/customers/${params.customer_code}/power_plants`, { params });
  },
  // 相対取引先 発電所 選択
  async updateRelativeTradingSite(condition, params) {
    return await api.put(`/aggregators/${condition.aggregator_id}/areas/${condition.area_id}/negotiation/customers/${condition.customer_code}/power_plants`, params);
  },
  // 相対取引先 権限照会
  async checkNegotiationCustomersBasicSettings() {
    return await api.get(`/negotiation/customers/display`);
  },
  // 相対取引先 固定取引量・価格 照会
  // async findRelativeTradingPriceAmount(aggregator_id, customer_code) {
  //   return await api.get(`/aggregators/${aggregator_id}/negotiation/customers/${customer_code}/quantity_and_cost`);
  // },
  // 相対取引先 固定取引量・価格 更新
  // async updateRelativeTradingPriceAmount(aggregator_id, customer_code, params) {
  //   return await api.put(`/aggregators/${aggregator_id}/negotiation/customers/${customer_code}/quantity_and_cost`, params);
  // },

  // アグリゲータ基本設定 照会
  async findAggregatorBasicSettings(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/basic_settings`);
  },
  // アグリゲータ基本設定 更新
  async updateAggregatorBasicSettings(aggregator_id, params) {
    return await api.put(`/aggregators/${aggregator_id}/basic_settings`, params);
  },

  // アグリゲータ基本設定 発調契約 更新
  async updateAggregatorBasicSettingsTradingPowerSupplyContract(aggregator_id, params) {
    return await api.put(`/aggregators/${aggregator_id}/basic_settings/trading_power_supply_contract`, params);
  },

  // スポット市場比率検索 
  async searchMarketPlan(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/market_plan`);
  },
  // スポット市場比率 更新
  async updateMarketPlan(params, aggregator_id) {
    return await api.put(`/aggregators/${aggregator_id}/market_plan`, params);
  },
  // 監視設定 照会
  async findMonitoringSettings(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/monitoring_settings`);
  },
  // 監視設定 更新
  async upsertBatteryNotificationInterval(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/monitoring_settings`,params);
  },
  // タイムシフト計画 検索
  async searchTimeShift(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/time_shift`);
  },
  // タイムシフト計画 更新
  async updateTimeShift(params, aggregator_id) {
    return await api.put(`/aggregators/${aggregator_id}/time_shift`, params);
  },
  // GC前インバランス回避 照会
  async searchGcBeforeImbalance(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/gc_before/imbalance/make_plan_cycle`);
  },
  // GC前インバランス回避 更新
  async updateGcBeforeImbalance(aggregator_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/gc_before/imbalance/make_plan_cycle`, params);
  },
  // GC後インバランス回避 照会
  async searchGcAfterImbalance(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/gc_after/imbalance/make_plan_cycle`);
  },
  // GC後インバランス回避 更新
  async updateGcAfterImbalance(aggregator_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/gc_after/imbalance/make_plan_cycle`, params);
  },
  // （前々日/前日）スポット市場取引計画 照会
  async searchSpotMarketPlanLowPercentile(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/spot_market_plan/low_percentile`);
  },
   // （前々日/前日）スポット市場取引計画 更新
   async updateSpotMarketPlanLowPercentile(aggregator_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/spot_market_plan/low_percentile`, params);
  },
  // （当日）時間前市場取引計画 照会
  async searchSameDayMarketTrading(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/same_day_market_trading`);
  },
   // （当日）時間前市場取引計画 更新
   async updateSameDayMarketTrading(aggregator_id, params) {
    return await api.post(`/aggregators/${aggregator_id}/same_day_market_trading`, params);
  },
  // アービトラージ計画 検索
  async searchArbitrage(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/arbitrage`);
  },
  // アービトラージ計画 更新
  async updateArbitrage(params, aggregator_id) {
    return await api.put(`/aggregators/${aggregator_id}/arbitrage`, params);
  },
  // 相対販売量通知設定 検索
  async searchNotified(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/customers/${params.customer_code}/notified`, params);
  },
  // 相対販売量通知設定 更新
  async updateNotified(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/customers/${params.customer_code}/notified`, params);
  },
  // 需要拠点一覧 検索
  async searchSelfConsignmentTrading(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/self-consignment-dpoints`);
  },
  // 自己託送販売量通知設定 検索
  async searchSelfConsignmentNotified(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/self-consignment-dpoints/${params.dpoint_id}/notified`);
  },
  // 自己託送販売量通知設定 更新
  async updateSelfConsignmentNotified(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/self-consignment-dpoints/${params.dpoint_id}/notified`, params);
  },
  // 市場別容量配分 検索
  async searchCapacityAllocationByMarket(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/capacity_allocation_by_market`);
  },
  // 市場別容量配分 更新
  async updateCapacityAllocationByMarket(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/capacity_allocation_by_market`, params);
  },
  // 価格予測設定 取得
  async searchPriceForecastSetting() {
    return await api.get(`/price-forecast-setting`);
  },
  // 価格予測設定 更新
  async updatePriceForecastSetting(params) {
    return await api.put(`/price-forecast-setting/update`, params);
  },
  // 時間前市場 アイスバーグ注文量 照会
  async searchSameDayMarketIcebergOrderQuantity(condition) {
    return await api.get(`/aggregators/${condition.aggregator_id}/same_day_market/iceberg_order_quantity`);
  },
  // 時間前市場 アイスバーグ注文量 登録
  async updateSameDayMarketIcebergOrderQuantity(condition, params) {
    return await api.put(`/aggregators/${condition.aggregator_id}/same_day_market/iceberg_order_quantity`, params);
  },
  // 託送損失率設定 検索
  async searchLossRate(aggregator_id) {
    return await api.get(`/aggregators/${aggregator_id}/consignment_loss_rate`);
  },
  // 託送損失率設定 登録
  async createLossRate(aggregator_id, payload) {
    return await api.post(`/aggregators/${aggregator_id}/consignment_loss_rate`, payload)
  },
  // 託送損失率設定 更新
  async updateLossRate(aggregator_id, loss_rate_id, payload) {
    return await api.put(`/aggregators/${aggregator_id}/loss_rate/${loss_rate_id}/consignment_loss_rate`, payload)
  },
  // 託送損失率設定 削除
  async deleteLossRate(aggregator_id, loss_rate_id) {
    return await api.delete(`/aggregators/${aggregator_id}/loss_rate/${loss_rate_id}/consignment_loss_rate`);
  },
}

export default service;