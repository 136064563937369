import api from './api';

const service = {
    async search(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.file_date}/calculation`, { params });
    },
    async download(params) {
        return await api.get(`/files/calculation/${params.file_id}`, { responseType:'blob', dataType:'binary' });
    }
}
export default service;