import api from './api';

const service = {
  async searchWorkStatus(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/buisiness_implement/status`, { params });
  },
  async search(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resource-status/areas/${params.area_id}`, { params });
  },
  async searchPersonalMessage(params) {
    return await api.get(`/users/dates/${params.target_date}/message/personal?type=${params.display_type}`);
  },
  async searchPowerTraderMessage(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/dates/${params.target_date}/message/power_trader?type=${params.display_type}`);
  },
  async searchBid(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/dates/${params.target_date}/trading/status`, { params });
  },
}

export default service;
