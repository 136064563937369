import { BID_STATUS_TYPE } from '@/constants';

//簡単な空判定
export function isEmpty(val) {
  return val === '' || val === null || val === undefined;
}

// パディング
export function padStart(str, len, pad) {
  if (isEmpty(str)) str = '';
  return str.padStart(len, pad)
}

// 数値のカンマ編集
export function numberFormat(val) {

  if (isEmpty(val)) return val;
  return new Intl.NumberFormat([], { maximumSignificantDigits: 20 }).format(val);

}

// 汎用型の数値書式 params.group=true カンマ区切り params.dec=n 少数桁そろえ
export function numberFormatter(params) {
  params = params || {group:true};
  let option = {};
  if( !isEmpty(params.group) ) option.useGrouping = params.group;
  if( !isEmpty(params.dec) ) {
    // 小数点
    option.minimumFractionDigits = params.dec; // この桁に足りない場合はゼロ埋め
    option.maximumFractionDigits = params.dec; // この桁を超えたら四捨五入
  }
  const formatter = new Intl.NumberFormat('ja-JP', option);
  return val=> {
    if (isEmpty(val)) return val;
    return formatter.format(val);
  }
}

// クローン
export function deepClone(object) {
  return JSON.parse(JSON.stringify(object));
}

// ページ移動
export function goto(link, params, option) {
  option = option || {};

  let query = '';
  if (params) {
    query = '?' + Object.entries(params).map(e => `${e[0]}=${encodeURIComponent(e[1])}`).join('&');
  }
  if (option.open) {
    window.open(link + query, '_blank');
  } else {
    window.location.href = link + query;
  }
}

// URLパラメータの取得
export function getUrlParameter() {
  let query = window.location.search;
  let params = {};
  if (query !== '') {
    query = query.substring(1); // ?を除外
    params = query.split('&').map((p) => p.split('='))
      .reduce((obj, e) => ({ ...obj, [e[0]]: decodeURIComponent(e[1]) }), {});
  }
  return params;
}

// PowerTraderデータ種別情報 データ種別フラグでの絞り込み取得
export function getPowerTraderDataKindByNegotiation(list_power_trader_data_kind, negotiation_flg) {
  return JSON.parse(JSON.stringify(list_power_trader_data_kind.filter( g => g.negotiation_flg === negotiation_flg )));
}

// エリア絞り込み取得
export function getAggregatorByArea(aggregatorId, list_aggregator, list_area) {
  if(aggregatorId === 'ALL' || aggregatorId === 'consortium') {
    return list_area;
  }
  let list_area_byAggregator = [];
  let data = JSON.parse(JSON.stringify(list_aggregator.filter( g => g.aggregator_id === aggregatorId )))

  if(data[0]) {
    let boolean = data[0].list_energy_area;
    if(Object.keys(boolean).length !== list_area.length) {
      boolean = data[0].list_bid_area;
    }
    Object.keys(boolean).forEach( key => {
      if(list_area.filter( v=> {
        v.area_id === key
      })) {
        if(boolean[key] === 'true') {
            list_area_byAggregator.push({ 
            "area_id": key, 
            "area_name": list_area[key-1].area_name
          })
        }
      }
    })
  }
  return list_area_byAggregator;
}

// エリア絞り込み取得(相対取引先エリア)
export function getAggregatorByAreaNegotiation(aggregatorId, list_aggregator, list_area) {
  if(aggregatorId === 'ALL' || aggregatorId === 'consortium') {
    return list_area;
  }
  let list_area_byAggregator = [];
  let data = JSON.parse(JSON.stringify(list_aggregator.filter( g => g.aggregator_id === aggregatorId )))

  if(data[0]) {
    let boolean = data[0].list_energy_area;
    if(Object.keys(boolean).length !== list_area.length) {
      boolean = data[0].list_negotiation_area;
    }
    Object.keys(boolean).forEach( key => {
      if(list_area.filter( v=> {
        v.area_id === key
      })) {
        if(boolean[key] === 'true') {
            list_area_byAggregator.push({ 
            "area_id": key, 
            "area_name": list_area[key-1].area_name
          })
        }
      }
    })
  }
  return list_area_byAggregator;
}

// エリア絞り込み取得(通常エリア＋相対取引先エリア)
export function getAggregatorByAreaAndNego(aggregatorId, list_aggregator, list_area) {
  if(aggregatorId === 'ALL' || aggregatorId === 'consortium') {
    return list_area;
  }
  let list_area_byAggregator = [];
  let data = JSON.parse(JSON.stringify(list_aggregator.filter( g => g.aggregator_id === aggregatorId )))

  if(data[0]) {
    let boolean      = data[0].list_energy_area;
    let boolean_nego = data[0].list_energy_area;
    if(Object.keys(boolean).length !== list_area.length) {
      boolean      = data[0].list_bid_area;
      boolean_nego = data[0].list_negotiation_area;
    }
    Object.keys(boolean).forEach( key => {
      if(list_area.filter( v=> {
        v.area_id === key
      })) {
        if(boolean[key] === 'true' || boolean_nego[key] === 'true') {
            list_area_byAggregator.push({ 
            "area_id": key, 
            "area_name": list_area[key-1].area_name
          })
        }
      }
    })
  }
  return list_area_byAggregator;
}

// LISTの先頭に指定なしを追加する
export function addListBlank(ary, value, text) {
  value = value || 'value';
  text = text || 'text';
  let head = {};
  head[value] = '';
  head[text] = '指定なし';
  if (ary.length > 0) {
    let clone = JSON.parse(JSON.stringify(ary));
    return [head, ...clone];
  }
  return [head];
}

// LISTの先頭に指定なし(ValueはALL)を追加する
export function addListBlankAll(ary, value, text) {
  value = value || 'value';
  text = text || 'text';
  if (ary.length > 0) {
    let clone = JSON.parse(JSON.stringify(ary));
    let head = {};
    head[value] = 'ALL';
    head[text] = '指定なし';
    return [head, ...clone];
  }
  return [];
}

// 日付フォーマット
export function formatDate(dt) {
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth() + 1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  return (y + '-' + m + '-' + d);
}

// 日時分フォーマット
export function formatDateTime(dt) {
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth() + 1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  const h = ('00' + dt.getHours()).slice(-2);
  const min = ('00' + dt.getMinutes()).slice(-2);
  const s = ('00' + dt.getSeconds()).slice(-2);
  return (y + '-' + m + '-' + d + 'T' + h + ':' + min + ':' + s + '+09:00');
}

// 時分フォーマット hh:mm
export function formatHourMinues(dt) {
  const h = ('00' + dt.getHours()).slice(-2);
  const min = ('00' + dt.getMinutes()).slice(-2);
  return (h + ':' + min);
}

// 当日日付を取得する
export function getToday(isDate) {
  let today = new Date();
  if (isDate) {
    return today;
  }
  return formatDate(today);
}

// 日付を加算する
export function addDays(base, days) {
  let result = new Date(base.getTime())
  result.setDate(base.getDate() + days);
  return result;
}

// ISO8601から変換用
export function changeFormatISO(time, columnList) {
  columnList.forEach( column => {
    time.forEach( value => {
      let timeFormat = '';
      if(value[column]) {
        timeFormat = formatDateISO(value, column);
      }
      // フォーマット変更した日時をセット
      value[column] = timeFormat;
    })
    return time;
  })
}
export function formatDateISO (date, column) {
  let time = new Date(date[column]);
  // 日時フォーマット変更(yyyy-mm-dd hh:mm:ss)
  let formatted = time.getFullYear()
    + "-" + ('0' + (time.getMonth() + 1)).slice(-2)
    + "-" + ('0' + time.getDate()).slice(-2)
    + " " + ('0' + time.getHours()).slice(-2)
    + ":" + ('0' + time.getMinutes()).slice(-2)
    + ":" + ('0' + time.getSeconds()).slice(-2)
  return formatted;
}

export function likesAll (param, list) {
  return param.length === list.length;
}

export function likesSome (param,boolean) {
  return param.length > 0 && !boolean
}

// v-select multiple すべて選択ボタンのアイコン
export function icon (param, list) {
  let booleanAll = likesAll(param,list);
  let booleanSome = likesSome(param,booleanAll);
  
  if (booleanAll) return 'mdi-close-box'
  if (booleanSome) return 'mdi-minus-box'
  return 'mdi-checkbox-blank-outline'
}
// 数値の単位変換
export function unitConversion(val) {
  if ( isEmpty(val) ) return val;
  val = Math.round(val /1000 * 1000) / 1000;
  return new Intl.NumberFormat([], { maximumSignificantDigits: 20 }).format(val)
}

// 入札状況名取得
export function getBidStatusTypeName(status) {
  if ( status === BID_STATUS_TYPE.MAKING ) return '作成中';
  else if ( status === BID_STATUS_TYPE.PENDING ) return '承認待ち';
  else if ( status === BID_STATUS_TYPE.BIDDING ) return '入札中';
  else if ( status === BID_STATUS_TYPE.PARTCONTRACT ) return '一部約定';
  else if ( status === BID_STATUS_TYPE.CONTRACTED ) return '約定済み';
  else if ( status === BID_STATUS_TYPE.UNSUCCESSFUL ) return '不落札';
  else if ( status === BID_STATUS_TYPE.PARTIAL ) return '一部不落札';
  else if ( status === BID_STATUS_TYPE.FAILURE ) return '入札失敗';
  else if ( status === BID_STATUS_TYPE.CLOSE ) return '受付終了';
  else return '―';
}

// メニュー情報からリンク先への権限があるかどうか判定
export function isAuthedLink(link) {
  let result = false;
  const menuJson = JSON.parse(localStorage.getItem('menuJson'));
  let url = "/" + link;
  if ( !isEmpty(menuJson.level1) ) {
    menuJson.level1.some( menu1 => {
      if ( menu1.displayUrl === url ) {
        result = true;
        return;
      }
      
      if ( !isEmpty(menu1.level2) ) {
        menu1.level2.some( menu2 => {
          if ( menu2.displayUrl === url ) {
            result = true;
            return;
          }
              
          if ( !isEmpty(menu2.level3) ) {
            menu2.level3.some( menu3 => {
              if ( menu3.displayUrl === url ) {
                result = true;
                return;
              }
            });
          }
        });
      }
    });
  }
  return result;
}

export default {
  isEmpty,
  padStart,
  numberFormat,
  numberFormatter,
  deepClone,
  goto,
  getUrlParameter,
  getAggregatorByArea,
  addListBlank,
  formatDate,
  getToday,
  unitConversion,
  likesAll,
  likesSome,
  icon,
}

