import api from './api';

const service = {

  async search(params) {
    let res = await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/spot_market/trading/plan`);
    res.data.dataList.forEach( item => {
      // 入力チェックメッセージ用
      item._message = Object.keys(item).reduce( (message, key) => {
        message[key] = [];
        return message;
      }, {});
      // 元の値を保持
      item._origin = JSON.parse(JSON.stringify(item));
    });
    
    return res;
  },
  async update(params, payload) {
    payload.dataList.forEach( item => {
      delete item._message;
      delete item._origin;
    });
    return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/spot_market/trading/plan`, payload);
  },
  async download(params) {
    return await api.get(`/files/csv/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/plan`, { params, responseType:'blob', dataType:'binary' });
  },
}

export default service;