import api from './api';

const service = {
  async searchPplant(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/power_plants/`, { params });
  },
  async createPplant(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/self_consignment/power_plants`, payload);
  },
  async updatePplant(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/self_consignment/power_plants/${payload.pplant_id}`, payload);
  },
  async deletePplant(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/self_consignment/power_plants/${payload.pplant_id}`);
  },
  async inquirePplantResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/self_consignment/power_plants/${payload.pplant_id}/resources/`);
  },
  async searchPplantResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/power_plants/${params.pplant_id}/resources/select`, { params });
  },
  async updatePplantResource(payload, aggregator_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/self_consignment/power_plants/${pplant_id}/resources/`, payload);
  },
  async updatePplantConfig(payload, aggregator_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/self_consignment/power_plants/${pplant_id}/config/`, payload);
  },
  async searchBgroup(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/power_generation_groups/`, { params });
  },
  async createBgroup(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/self_consignment/power_generation_groups`, payload);
  },
  async updateBgroup(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/self_consignment/power_generation_groups/${payload.bgroup_id}`, payload);
  },
  async deleteBgroup(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/self_consignment/power_generation_groups/${payload.bgroup_id}`);
  },
  async updateJgroup(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/self_consignment/power_generation_groups/${payload.bgroup_id}/jgroups`, payload);
  },
  async updateAutoConfigRule(params, payload) {
    return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/power_generation_groups/${params.bgroup_id}/auto_config_rule`, payload);
  },
  async inquireBgroupResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/self_consignment/power_generation_groups/${payload.bgroup_id}/power_plants/`);
  },
  async searchBgroupResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/power_generation_groups/${params.bgroup_id}/resources/select`, { params });
  },
  async updateBgroupResource(payload, aggregator_id, bgroup_id) {
    return await api.put(`/aggregators/${aggregator_id}/self_consignment/power_generation_groups/${bgroup_id}/power_plants/`, payload);
  },

  async searchDgroup(params) {  
    return await api.get(`/aggregators/${params.aggregator_id}/demand_groups`, { params });
  },
  async createDgroup(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/demand_groups`, payload);
  },
  async updateDgroup(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}`, payload);
  },
  async deleteDgroup(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}`);
  },

  async inquireDpointResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}/demand_points`);
  },
  async createDpoint(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}/demand_points`, payload);
  },
  async updateDpoint(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}/demand_points/${payload.dpoint_id}`, payload);
  },
  async deleteDpoint(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/demand_groups/${payload.jgroup_id}/demand_points/${payload.dpoint_id}`);
  },
  async uploadLowNscCooperate(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`files/csv/aggregators/${params.aggregator_id}/pplants/${params.pplant_id}/self_consignment/power_plants/low_nsc_cooperate`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  async createTempleteLowNscCooperate() {
    return await api.get(`/files/templates/self_consignment/power_plants/low_nsc_cooperate`, { responseType:'blob', dataType:'binary' });
  },
  async searchNscLowResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/power_plants/low_nsc_cooperate`, { params });
  },
  async deleteNscLowResource(payload, params) {
    return await api.post(`/aggregators/${params.aggregator_id}/pplants/${params.pplant_id}/self_consignment/power_plants/low_nsc_cooperate`, payload );
  },
}

export default service;
