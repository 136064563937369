import api from './api';

const service = {
    /**
     * 発電量予測＆実績（前日予測） ダウンロード
     * @param {*} aggregator_id 
     * @param {*} resource_type PV:1 , WP:2 , HP:5
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getForecast(aggregator_id, resource_type, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/resource_types/${resource_type}/electric_power/forecast_and_actual/previous_day`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 発電量予測＆実績（当日予測） ダウンロード
     * @param {*} aggregator_id 
     * @param {*} resource_type PV:1 , WP:2 , HP:5
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getForecastToday(aggregator_id, resource_type, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/resource_types/${resource_type}/electric_power/forecast_and_actual/same_day`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * スポット市場価格予測＆実績（前日予測） ダウンロード
     * @param {Object} params 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getSpotMarketPrice(params) {
        return await api.get(`/files/csv/spot_market/price/forecast_and_actual/previous_day`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 時間前市場価格予測＆実績（前日予測） ダウンロード
     * @param {Object} params 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getSameDayMarketPrice(params) {
        return await api.get(`/files/csv/same_day_market/price/forecast_and_actual/previous_day`, { params });
    },

    /**
     * 発電機 発電計画＆実績 ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getGeneratorElectricPower(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/resources/generators/electric_power/plan_and_actual`, { params });
    },

    /**
     * 三次調整力②価格予測・実績データ（前日予測 3時間ブロック）ダウンロード
     * @param {Object} params 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getEprxTertiary2Price(params) {
        return await api.get(`/files/csv/eprx/tertiary2/price/forecast_and_actual/previous_day`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * インバランス精算単価予測・実績データ（30分値）ダウンロード
     * @param {Object} params 
     * @param {number} params.area_id
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getImbalanceSettlementUnitPrice(params) {
        return await api.get(`/files/csv/imbalance_settlement/unit_price/forecast_and_actual`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 予測分析MAPEデータ（日単位）
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {number} params.resource_type_code
     * @param {number} params.forecast_model 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getForecastMape(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/mape/forecast/day`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 静止点予測・実績データ（30分値）
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {number} params.bgroup_id 
     * @param {number} params.pplant_id 
     * @param {number} params.resource_type_code 
     * @param {number} params.forecast_model 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @param {string} params.kind
     * @returns 
     */
    async getForecastHistory(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/history/forecast_and_actual`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 需要拠点需要予測・実績データ（前日予測 30分値） ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
        async getDemandForecastActualPreviousDay(aggregator_id, params) {
            return await api.get(`/files/csv/aggregators/${aggregator_id}/demand/forecast_and_actual/previous_day`, { params, responseType:'blob', dataType:'binary' });
        },

    /**
     * 需要拠点需要予測・実績データ（当日予測 30分値） ダウンロード
     * @param {*} aggregator_id 
     * @param {Object} params 
     * @param {number} params.area_id 
     * @param {string} params.start_date YYYY-MM-DD 
     * @param {string} params.end_date YYYY-MM-DD 
     * @param {string} params.file_name 
     * @returns 
     */
    async getDemandForecastActualSameDay(aggregator_id, params) {
        return await api.get(`/files/csv/aggregators/${aggregator_id}/demand/forecast_and_actual/same_day`, { params, responseType:'blob', dataType:'binary' });
    },
}

export default service;
