import api from './api';

const service = {
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resources/`, { params });
  },
  async findResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resources/${params.resource_id}`);
  },
  async createResource(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/resources`, payload);
  },
  async updateResource(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/resources/${payload.resource_id}`, payload);
  },
  async deleteResource(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/resources/${payload.resource_id}`);
  },
  async searchPplant(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/power_plants/`, { params });
  },
  async createPplant(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/power_plants`, payload);
  },
  async updatePplant(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/power_plants/${payload.pplant_id}`, payload);
  },
  async deletePplant(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/power_plants/${payload.pplant_id}`);
  },
  async inquirePplantResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/power_plants/${payload.pplant_id}/resources/`);
  },
  async searchPplantResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/power_plants/${params.pplant_id}/resources/select`, { params });
  },
  async updatePplantResource(payload, aggregator_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/power_plants/${pplant_id}/resources/`, payload);
  },
  async updatePplantConfig(payload, aggregator_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/power_plants/${pplant_id}/config/`, payload);
  },
  async searchBgroup(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/balancing_groups/`, { params });
  },
  async createBgroup(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/balancing_groups`, payload);
  },
  async updateBgroup(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/balancing_groups/${payload.bgroup_id}`, payload);
  },
  async deleteBgroup(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/balancing_groups/${payload.bgroup_id}`);
  },
  async searchResourceConfig(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resources/config`, { params });
  },
  async updateResourceConfig(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/resources/${payload.resource_id}/config`, payload);
  },
  async updateResourceConfigOperateFlg(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/resources/${payload.resource_id}/config/operate_flg`, payload);
  },
  async inquireBgroupResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/balancing_groups/${payload.bgroup_id}/power_plants/`);
  },
  async searchBgroupResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/balancing_groups/${params.bgroup_id}/resources/select`, { params });
  },
  async updateBgroupResource(payload, aggregator_id, bgroup_id) {
    return await api.put(`/aggregators/${aggregator_id}/balancing_groups/${bgroup_id}/power_plants/`, payload);
  },
  async updateResourceConfigDetail(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/resources/${payload.resource_id}/config/forecast`, payload);
  },
  async searchContract(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/contract/`, { params });
  },
  async createContract(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/contract`, payload);
  },
  async updateContract(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/contract/${payload.contract_id}`, payload);
  },
  async deleteContract(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/contract/${payload.contract_id}`);
  },
  async inquireContractResource(payload) {
    return await api.get(`/aggregators/${payload.contract_aggregator_id}/contract/${payload.contract_id}/power_plants/`);
  },
  async searchContractResource(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/resources/select`, { params });
  },
  async updateContractResource(payload, aggregator_id, contract_id) {
    return await api.put(`/aggregators/${aggregator_id}/contract/${contract_id}/power_plants/`, payload);
  },
  async searchContractCustomer(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/customers/select`, { params });
  },
  async updateContractCustomer(payload, contract_aggregator_id, contract_id) {
    return await api.put(`/aggregators/${contract_aggregator_id}/contract/${contract_id}/customers/`, payload);
  },
  async searchContractUser(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/users/select`, { params });
  },
  async updateContractUser(payload, contract_aggregator_id, contract_id) {
    return await api.put(`/aggregators/${contract_aggregator_id}/contract/${contract_id}/users/`, payload);
  },
  async searchContractConditionList(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/condition/list?area_id=${params.area_id}`, params);
  },
  async searchContractConditionDetail(params) {
    return await api.get(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/areas/${params.area_id}/customers/${params.customer_code}/condition/select`, { params });
  },
  async updateContractCondition(params, payload) {
    let pplantId = payload.pplant_id != undefined? payload.pplant_id: '';
    return await api.put(`/aggregators/${params.contract_aggregator_id}/contract/${params.contract_id}/areas/${params.area_id}/customers/${params.customer_code}/condition/update`, { 
        contract_condition_id: payload.contract_condition_id,
        pplant_id: pplantId
    });
  },
  async uploadLowNscCooperate(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`files/csv/aggregators/${params.aggregator_id}/pplants/${params.pplant_id}/power_plants/low_nsc_cooperate`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  async createTempleteLowNscCooperate() {
    return await api.get(`/files/templates/power_plants/low_nsc_cooperate`, { responseType:'blob', dataType:'binary' });
  },
  async searchNscLowResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/power_plants/low_nsc_cooperate`, { params });
  },
  async deleteNscLowResource(payload, params) {
    return await api.post(`/aggregators/${params.aggregator_id}/pplants/${params.pplant_id}/power_plants/low_nsc_cooperate`, payload );
  },
}

export default service;
