import api from './api';
import { deepClone } from '@/util';

export default {
  async request(params, items) {
    let payload = deepClone(params);
    payload.pplant_list = deepClone(items).map( p => {
      if (p.count_bt === 0) {
        p.battery = {};
      }
      if (p.count_tp === 0) {
        p.thermal = {};
      }
      return p;
    });
    
    return await api.post(`/aggregators/${params.aggregator_id}/simulation_request`, payload);
  },
  async searchSite(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/simulation_request/power_plants`, { params });
  },
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/simulation_request/resource`, { params });
  },
  async findBattery(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resources/${params.resource_id}/simulation_request/battery`);
  },
  async searchSimulation(params) {
    return await api.get(`aggregators/${params.aggregator_id}/simulation_request/simulation`, { params });
  },
  async searchSimulationDetail(simulationId) {
    return await api.get(`simulations/${simulationId}/simulation_request/simulation_select`, { simulationId });
  },
}