import api from './api';

const service = {
    // リスト取得 (発電所)
    async getSuppressionSite(params) {
      return await api.get(`/aggregators/${params.aggregator_id}/suppression/pplants?area_id=${params.energy_area_id}&bgroup_id=${params.bgroup_id}`, { params });
    },
    async downloadFixed(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/power_plants/${params.pplant_id}/suppression_fixed/`,{ params, responseType:'blob', dataType:'binary' });
    },
    async downloadUpdate(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/power_plants/${params.pplant_id}/suppression_update/`,{ params, responseType:'blob', dataType:'binary' });
    },
}

export default service;