import api from './api';
import { deepClone } from '@/util';

const service = {
    async searchBGroupList(aggregator_id, area_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/bgroup_pplant/forecast_processing/?area_id=${area_id}`, { params });
    },

    async searchForecastProcessingData(aggregator_id, pplant_id) {
        return await api.get(`/aggregators/${aggregator_id}/power_plants/${pplant_id}/forecast_processing/`);
    },

    async updateForecastProcessingData(result) {
        let payload = deepClone(result)

        let aggregatorId = payload.aggregator_id;
        delete payload.aggregator_id;
        let pplant_id = payload.pplant_id;
        delete payload.area_id;
  
        if (payload.forecastProcessing.sunday_flg == "") payload.forecastProcessing.sunday_flg = 0;
        if (payload.forecastProcessing.monday_flg == "") payload.forecastProcessing.monday_flg = 0;
        if (payload.forecastProcessing.tuesday_flg == "") payload.forecastProcessing.tuesday_flg = 0;
        if (payload.forecastProcessing.wednesday_flg == "") payload.forecastProcessing.wednesday_flg = 0;
        if (payload.forecastProcessing.thursday_flg == "") payload.forecastProcessing.thursday_flg = 0;
        if (payload.forecastProcessing.friday_flg == "") payload.forecastProcessing.friday_flg = 0;
        if (payload.forecastProcessing.saturday_flg == "") payload.forecastProcessing.saturday_flg = 0;
        
        return await api.put(`/aggregators/${aggregatorId}/power_plants/${pplant_id}/forecast_processing/`, payload);    
    },
}

export default service;
