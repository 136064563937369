import api from './api';

const service = {
    async searchJGroupList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/jgroup_list/`, { params });
    },
    async searchDemandDataByArea(aggregator_id, area_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/dates/${targetDate}/demand/consignment_quantity`);
    },
    async searchDemandDataByJGroup(aggregator_id, jgroup_id, targetDate, params) {
        return await api.get(`/aggregators/${aggregator_id}/jgroups/${jgroup_id}/dates/${targetDate}/demand/consignment_quantity`, { params });
    },
}

export default service;
