import api from './api';

const service = {
    // 発電販売計画 当日/翌日
    async searchSubmissionSalesPlan(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan`);
    },
    async updateSalesPlan(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/making`);
    },
    async DownloadSalesPlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan`)
    },
    async updateSubmissionOctto(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/upload`)
    },

    // 発電販売計画 週間
    async searchSubmissionSalesPlanWeek(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/weeks_plan`);
    },
    async updateSalesPlanWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/weeks_making`);
    },
    async DownloadSalesPlanXmlWeek(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/weeks_plan`)
    },
    async updateSubmissionOcttoWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/weeks_upload`)
    },

    // 需要調達計画 当日/翌日
    async searchSubmissionSupplySalesPlan(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan?demand_flag=${params.demand_flag}`);
    },
    async updateSupplySalesPlan(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/making?demand_flag=${params.demand_flag}`);
    },
    async DownloadSupplySalesPlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan?demand_flag=${params.demand_flag}`)
    },
    async DownloadSupplyDemandSalesPlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/supply/trading/plan?demand_flag=${params.demand_flag}`, 
        {
            responseType: 'arraybuffer',
            headers: { Accept: 'application/zip' },
        })
    },
    async updateSupplySubmissionOctto(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/upload?demand_flag=${params.demand_flag}`)
    },
    // 需要調達計画 週間
    async searchSubmissionSupplySalesPlanWeek(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/weeks_plan`);
    },
    async updateSupplySalesPlanWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/weeks_making`);
    },
    async DownloadSupplySalesPlanXmlWeek(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/weeks_plan`)
    },
    async updateSupplySubmissionOcttoWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/weeks_upload`)
    },

    // 計画受付検索
    async searchReceptionSalesPlan(aggregator_id,params) {
        const baseUrl = `/aggregators/${aggregator_id}/self_consignment/electric_power/trading/plan/acceptance`;
        const paramsUrl = Object.entries(params).map((e) => {
            let key = e[0];
            let value = e[1];
            return `${key}=${value}`;
          }).join("&");
        const url = baseUrl + '?' + paramsUrl;
        return await api.get(url);
    },

    // 自動/手動提出方法
    async searchAutoSubmissionConfig(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/electric_power/trading/plan/automatic_submission`)
    },
    async updateAutoSubmissionConfig(params){
        return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/electric_power/trading/plan/automatic_submission?occto_auto_flag=${params.occto_auto_flag}&occto_weeks_auto_flag=${params.occto_weeks_auto_flag}&occto_2days_later_auto_flag=${params.occto_2days_later_auto_flag}&demand_auto_flag=${params.demand_auto_flag}&demand_weeks_auto_flag=${params.demand_weeks_auto_flag}&demand_2days_later_auto_flag=${params.demand_2days_later_auto_flag}` )
    },

    // 発電販売計画 翌々日
    async searchSubmissionSalesPlanTwoDaysLater(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/two_days_later_plan`);
    },
    async updateSalesPlanTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/two_days_later_making`);
    },
    async DownloadSalesPlanXmlTwoDaysLater(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/two_days_later_plan`)
    },
    async updateSubmissionOcttoTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/electric_power/trading/plan/two_days_later_upload`)
    },
    // 需要調達計画 翌々日
    async searchSubmissionProcurePlanTwoDaysLater(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/two_days_later_plan`);
    },
    async updateProcurePlanTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/two_days_later_making`);
    },
    async DownloadProcurePlanXmlTwoDaysLater(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/two_days_later_plan`);
    },
    async updateSupplySubmissionOcttoTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/self_consignment/demand_power/trading/plan/two_days_later_upload`);
    },
}

export default service;
