import api from './api';

const service = {
    async searchBGroupList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/self_consignment_bgroup_list/`, { params });
    },
    async searchElectricDataByArea(aggregator_id, area_id, targetDate) {
        return await api.get(`/aggregators/${aggregator_id}/areas/${area_id}/dates/${targetDate}/self_consignment_bgroup/consignment_quantity/`);
    },
    async searchElectricDataByBGroup(aggregator_id, bgroup_id, targetDate, params) {
        return await api.get(`/aggregators/${aggregator_id}/bgroups/${bgroup_id}/dates/${targetDate}/self_consignment_bgroup/consignment_quantity/`, { params });
    },
}

export default service;
