import api from './api';

const service = {
    async search(params) {
        return await api.get(`/analysis_report_type/${params.analysis_report_type}/simulation_result_analysis`, { params });
    },
    async download(params) {
        return await api.get(`/files/zip/simulation_result_analysis/${params.analysis_report_id}`, 
        {
            responseType: 'arraybuffer',
            headers: { Accept: 'application/zip' },
        })
    }
}
export default service;