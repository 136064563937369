import api from './api';

const service = {

  // 検索処理
  async searchFile(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.file_date}/calculation/file_upload`, { params });
  },
  // 精算ファイルアップロード
  async createFile(payload, file) {
    const form = new FormData();
    form.append('file', file);
    let baseUrl = `/aggregators/${payload.aggregator_id}/areas/${payload.area_id}/dates/${payload.file_date}/calculation/file_upload`;

    if(payload.file_name != null) {
      baseUrl = baseUrl + `?file_name=${payload.file_name}`
    }
    return await api.post(baseUrl, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 更新処理
  async updateFile(payload) {
    return await api.put(`/calculation/file_upload/${payload.file_id}?area_id=${payload.area_id}&file_date=${payload.file_date}&file_name=${payload.file_name}`, payload );
  },
  // 削除処理
  async deleteFile(params) {
    return await api.delete(`/calculation/file_upload/${params.file_id}`, { params });
  },
}

export default service;
