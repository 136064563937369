// 入力チェックルール

const isEmpty = v => v === null || v === '' || v === undefined;

// 必須入力
export const required = v => !isEmpty(v) || '必ず入力してください';

// 必須入力
export const requiredTimeRange = v => {
  const resultMessage = '必ず入力してください';
  const values = v.split(' ~ ');
  if (values.length === 2) {
    return (!isEmpty(values[0]) && !isEmpty(values[1])) || '必ず入力してください';
  }
  return resultMessage;
};

// ASCII文字
const asciiReg = /^[\x20-\x7E]*$/;
export const ascii = v => {
  if (isEmpty(v)) return true;
  return asciiReg.test(v) || 'ASCII文字を入力してください(半角英数記号)';
}

// 半角英数字
const alphaNumReg = /^[0-9a-z]+$/i;
export const alphaNum = v => {
  if (isEmpty(v)) return true;
  return alphaNumReg.test(v) || '半角英数字を入力してください';
}

// 数字
const digitReg = /^[0-9]+$/;
export const digit = v => {
  if (isEmpty(v)) return true;
  return digitReg.test(v) || '数字を入力してください';
}

// 数値、桁数
export const numeric = (int, dec) => {
  let reg, msg;
  const checkMinusZero = '-0'; // マイナス0禁止
  const zeroStart = new RegExp('^-?0[0-9]+'); // 0始まりチェック
  if (!isEmpty(int) && !isEmpty(dec)) {
    reg = new RegExp(`^-?[0-9]{1,${int}}(\\.[0-9]{1,${dec}})?$`);
    msg = `整数部${int}桁、小数部${dec}桁までの数値を入力してください`;
  } else if (!isEmpty(int) && (isEmpty(dec) || dec === 0)) {
    reg = new RegExp(`^-?[0-9]{1,${int}}$`);
    msg = `整数部${int}桁までの数値を入力してください`;
  } else if ((isEmpty(int) || int === 0) && !isEmpty(dec)) {
    reg = new RegExp(`^-?[0-9]+(\\.[0-9]{1,${dec}})?$`);
    msg = `小数部${dec}桁までの数値を入力してください`;
  } else {
    reg = new RegExp(`^-?[0-9]+(\\.[0-9]+)?$`);
    msg = `数値を入力してください`;
  }
  return v => {
    if (isEmpty(v)) return true;
    return (v !== checkMinusZero && !zeroStart.test(v) && reg.test(v)) || msg;
  }
}

// 金額数値、桁数
export const numericMoney = (int, dec) => {
  let reg, msg;
  const checkMinusZero = '-0'; // マイナス0禁止
  const zeroStart = new RegExp('^-?0[0-9]+'); // 0始まりチェック
  if (!isEmpty(int) && !isEmpty(dec)) {
    reg = new RegExp(`^-?[0-9]{1,${int}}(\\.[0-9]{1,${dec}})?$`);
    msg = `整数部${int}桁、小数部${dec}桁までの数値を入力してください`;
  } else if (!isEmpty(int) && (isEmpty(dec) || dec === 0)) {
    reg = new RegExp(`^-?[0-9]{1,${int}}$`);
    msg = `整数部${int}桁までの数値を入力してください`;
  } else if ((isEmpty(int) || int === 0) && !isEmpty(dec)) {
    reg = new RegExp(`^-?[0-9]+(\\.[0-9]{1,${dec}})?$`);
    msg = `小数部${dec}桁までの数値を入力してください`;
  } else {
    reg = new RegExp(`^-?[0-9]+(\\.[0-9]+)?$`);
    msg = `数値を入力してください`;
  }
  return v => {
    if (isEmpty(v)) return true;
    v = String(v).replaceAll(',','');
    return (v !== checkMinusZero && !zeroStart.test(v) && reg.test(v)) || msg;
  }
}

// 数値範囲
export const between = (min, max, msg) => {
  msg = msg || `${min}から${max}までの数値範囲を入力してください`;
  return v => {
    if (isEmpty(v)) return true;
    return min <= Number(v) && Number(v) <= max || msg;
  }
}

// 金額数値範囲
export const betweenMoney = (min, max, msg) => {
  msg = msg || `${min}から${max}までの数値範囲を入力してください`;
  return v => {
    if (isEmpty(v)) return true;
    v = Number(String(v).replaceAll(',',''));
    return min <= Number(v) && Number(v) <= max || msg;
  }
}

// 最小桁数
export const minLength = (min, msg) => {
  msg = msg || `最小${min}文字以上を入力してください`;
  return v => {
    if (isEmpty(v)) return true;
    return min <= v.length || msg;
  }
}

// 最大桁数
export const maxLength = (max, msg) => {
  msg = msg || `最大${max}文字以内で入力してください`;
  return v => {
    if (isEmpty(v)) return true;
    return v.length <= max || msg;
  }
}

// 最大桁数（半角文字は1文字、全角文字は2文字に数えます）
const strCountFullHalfCheck = /[ -~]/;
export const maxLengthFullHalf = (max, msg) => {
  msg = msg || `最大${max}文字以内で入力してください（半角文字は1文字、全角文字は2文字に数えます）。`;
  return v => {
    if (isEmpty(v)) return true;
    let len = 0;

    for (let i = 0; i < v.length; i++) {
    (v[i].match(strCountFullHalfCheck)) ? len += 1 : len += 2;
    }
    return len <= max || msg;
  }
}

// 任意の桁数
export const justLength = (size, msg) => {
  msg = msg || `${size}文字で入力してください`;
  return v => {
    if (isEmpty(v)) return true;
    return (v.length <= size && size <= v.length) || msg;
  }
}

// e-mail
const emailReg = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const email = v => {
  if (isEmpty(v)) return true;
  return emailReg.test(v) || '不正なe-mailアドレスです'
}

// csvUpload
const csvReg = /\.csv$/;
export const csvFileName = v => {
  if (isEmpty(v)) return true;
  return csvReg.test(v.name) || 'CSVファイルを選択してください'
}

// xmlUpload
const xmlReg = /\.xml$/;
export const xmlFileName = v => {
  if (isEmpty(v)) return true;
  return xmlReg.test(v.name) || 'XMLファイルを選択してください'
}

// upload(zipファイルは不可)
const zipReg = /\.zip$/;
export const zipFileName = v => {
  if (isEmpty(v)) return true;
  return !zipReg.test(v.name) || 'zipファイルはアップロードできません'
}

// 半角英数字(大文字可能、スペース不可)
const alphaNumNoSpaceReg = /^[0-9a-zA-Z]*$/;
export const alphaNumNoSpace = v => {
  if (isEmpty(v)) return true;
  return alphaNumNoSpaceReg.test(v) || '半角英数字を入力してください';
}

// スペースのみの入力は不可
const space = /^[\s]*$/g;
export const noAllSpace = v => {
  if (isEmpty(v)) return true;
  return !v.match(space) || 'スペースのみの入力は不可です';
}

// パスワード(半角英数字1文字ずつ、スペース不可)
const passwordReg = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,20}$/;
export const password = v => {
  if (isEmpty(v)) return true;
  return (!v.match(space) && passwordReg.test(v)) || '半角英字(大文字・小文字)、数字を1文字ずつ含めて、8文字以上入力してください';
}


// 特殊文字の使用禁止
const checkFigure = /[:;{}|^[\]'"\\?,&%]/;
export const noFigure = v => {
  if (isEmpty(v)) return true;
  return !v.match(checkFigure) || '記号(:;{}|^[]\'"/\\?,&%)は使用しないでください。';
}

// スペース及び特殊文字の使用禁止
const checkFigureAndSpace = /[:;{}|^[\]'"\\?,&%\s]/;
export const noSpaceAndNoFigure = v => {
  if (isEmpty(v)) return true;
  return (!v.match(checkFigureAndSpace)) || 'スペース、記号(:;{}|^[]\'"/\\?,&%)は使用しないでください。';
}

// スペース及び特殊文字の使用禁止(ファイル名)
const fileCheckFigureAndSpace = /[\\/:*?"<>|\s]/;
export const fileNoSpaceAndNoFigure = v => {
  if (isEmpty(v)) return true;
  return (!v.match(fileCheckFigureAndSpace)) || 'スペース、記号(:/*?<>\\|)は使用しないでください。';
}

// 指定日数の制限
export const dateRange = (range, msg) => {
  msg = msg || `${range}日以下の日数を指定してください。`;
  return v => {
    if (isEmpty(v)) return true;
    let dates = v.split(' ~ ');
    return (new Date(dates[1]) - new Date(dates[0]) + 1) / 86400000 <= range || msg;
  }
}

// 当日以降1年間の日付制限
export const overToday = v => {
  if (isEmpty(v)) return true;
  let start = new Date();
  var ys = start.getFullYear();
  var ms = ("00" + (start.getMonth()+1)).slice(-2);
  var ds = ("00" + start.getDate()).slice(-2);
  start = ys + "-" + ms + "-" + ds;
  let end = new Date();
  end.setDate(end.getDate() + 365);
  var ye = end.getFullYear();
  var me = ("00" + (end.getMonth()+1)).slice(-2);
  var de = ("00" + end.getDate()).slice(-2);
  end = ye + "-" + me + "-" + de;
  return (new Date(v) >= new Date(start) && new Date(v) <= new Date(end) || `本日以降1年間の日付を選択してください。`)
}
// 当日以降1年間の日付制限(複数日付選択)
export const overTodayDataRange = v => {
  if (isEmpty(v)) return true;
  let dates = v.split(' ~ ');
  let start = new Date();
  var ys = start.getFullYear();
  var ms = ("00" + (start.getMonth()+1)).slice(-2);
  var ds = ("00" + start.getDate()).slice(-2);
  start = ys + "-" + ms + "-" + ds;
  let end = new Date();
  end.setDate(end.getDate() + 365);
  var ye = end.getFullYear();
  var me = ("00" + (end.getMonth()+1)).slice(-2);
  var de = ("00" + end.getDate()).slice(-2);
  end = ye + "-" + me + "-" + de;
  return (new Date(dates[0]) >= new Date(start) && new Date(dates[0]) <= new Date(end)
       && new Date(dates[1]) >= new Date(start) && new Date(dates[1]) <= new Date(end) || `本日以降1年間の日付を選択してください。`)
}

// e-mails
const emailsReg = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const emails = v => {
  if (isEmpty(v)) return true;
  let mailsFlg = true;
  let mails = v.split(';');
  mails.forEach(mail => {
    if (!emailsReg.test(mail)){
      mailsFlg = false;
    }
  });
  return mailsFlg || '不正なe-mailアドレスです。'
}

// スペース及び特殊文字の使用禁止(セミコロンを含まない)
const checkFigureAndSpaceNotSem = /[:{}|^[\]'"\\?,&%\s]/;
export const noSpaceAndNoFigureNotSem = v => {
  if (isEmpty(v)) return true;
  return (!v.match(checkFigureAndSpaceNotSem)) || 'スペース、記号(:{}|^[]\'"/\\?,&%)は使用しないでください。';
}

// e-mailアドレスの件数カウント
export const emailsCount = (range) => {
  return v => {
    if (isEmpty(v)) return true;
    return v.trim().split(';').length <= range || 'e-mailアドレスの件数が'+ range +'件を超えています。'
  }
}

// text-areaの行数カウント
const delRowCount = /\n/g;
export const rowCount = (range) => {
  return v => {
    if (isEmpty(v)) return true;
    var count = ( v.match(delRowCount) || [] ).length + 1;
    return count <= range || range +'行以内で入力してください。'
  }
}

// text-areaの行ごとの文字数カウント
export const charCount = (range) => {
  return v => {
    if (isEmpty(v)) return true;
    let charFlg = true;
    let rows = v.split('\n');
    rows.forEach(row => {
      if (row.length > range){
        charFlg = false;
      }
    });

    return charFlg || '1行につき' + range + '文字以内で入力してください。'
  }
}

// 必須入力
export const memberRules = v => {
  return v?.length > 0 || '必ず選択してください';
};

// ファイル名の禁止文字チェック
const fileReg = /[\\/:*?"<>|]/;
export const fileName = v => {
  if (isEmpty(v)) return true;
  return !v.match(fileReg) || 'ファイル名には次の文字は使えません。 \\ / : * ? " < > |';
}

export default {
  required,
  requiredTimeRange,
  ascii,
  alphaNum,
  digit,
  numeric,
  numericMoney,
  between,
  betweenMoney,
  minLength,
  maxLength,
  maxLengthFullHalf,
  justLength,
  email,
  csvFileName,
  xmlFileName,
  zipFileName,
  fileName,
  alphaNumNoSpace,
  noAllSpace,
  password,
  noFigure,
  noSpaceAndNoFigure,
  fileNoSpaceAndNoFigure,
  dateRange,
  overToday,
  overTodayDataRange,
  emails,
  noSpaceAndNoFigureNotSem,
  emailsCount,
  rowCount,
  charCount,
  memberRules,
}