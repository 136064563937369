import api from './api';

const service = {

  async search(params) {
    let res = await api.get(`/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/plan`);
    //res.data.dataList.forEach( item => {
      // // 30個入力テスト用
      // for (let i = 6; i <= 30; i++) {
      //   item[`bid_quantity_${i}`] = '1';
      //   item[`bid_price_${i}`] = '1';
      // }

      // // 発電所別
      // item.pplants = [];
      // for (let p = 1; p <= 10; p++) {
      //   const pplant = {
      //     pplant_name: `発電所あああああああああああああああ${p}`,
      //     pplant_id: `${p}`,
      //     time_code_type: '',
      //     time: '',
      //     sell_price_lower: '999999.99',
      //     sellable_quantity: '-999999.99',
      //     buy_price_upper: '999999.99',
      //     buyable_quantity: '999999.99',
      //     reference_quantity: '',
      //     forecast_price: '',
      //   }
      //   for (let i = 1; i <= 30; i++) {
      //     pplant[`bid_quantity_${i}`] = '-999999.99';
      //     pplant[`bid_price_${i}`] = '999999.99';
      //   }
      //   item.pplants.push(pplant);
      // }
      // // 入力チェックメッセージ用
      // item._message = Object.keys(item).reduce( (message, key) => {
      //   message[key] = [];
      //   return message;
      // }, {});
      // // 元の値を保持
      // item._origin = JSON.parse(JSON.stringify(item));
    //});
    
    return res;
  },
  async update(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/plan`);
  },
  async copyRef(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/reference`);
  },
  async download(params) {
    return await api.get(`/files/csv/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/plan`, { params, responseType:'blob', dataType:'binary' });
  },
  async uploadBid(params, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.put(`/files/csv/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/bid`, form , {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async downloadBid(params, reference) {
    return await api.get(`/files/csv/aggregators/${params.aggregator_id}/kind/${params.data_kind}/areas/${params.area_id}/dates/${params.target_date}/spot_market/trading/bid`, { params: {reference}, responseType:'blob', dataType:'binary' });
  },
}

export default service;