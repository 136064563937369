import api from './api';

const service = {
  async create() {
    return await api.get(`/files/templates/assumption_sales_upload`, { responseType: 'blob', dataType: 'binary' });
  },

  async uploadAssumptionSales(file, aggregator_id, area_id) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${aggregator_id}/assumption_sales_upload/${area_id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}

export default service;