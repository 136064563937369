import api from './api';

const service = {

  // 検索処理
  async searchResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/resource-status-monitor/resources`, { params });
  },
}

export default service;
