import { padStart, deepClone } from '@/util';
import { createGetters, createMutations } from '@/store/util';
import { USER_TYPE, ROLE, WORKFLOW } from '@/constants';
import factory from '@/services/factory';
const service = factory.get('list');

// ドロップダウンリスト定義
// ほぼ変動のない固定のドロップダウンを定義する
const state = {
  // アグリゲーター
  list_aggregator: [],
  // 設置エリア
  list_area: [],
  // 電力エリア
  list_energy_area: [],
  // 価格エリア
  list_price_area: [],
  // 入札エリア
  list_bid_area: [],
  // 都道府県
  list_prefecture: [],
  // リソース種別
  list_resource_type: [],
  // 運用方法
  list_operation_method: [],
  // GWパターン
  list_gw_pattern: [],
  // パワーコンディショナ
  list_power_conditioner: [],
  // 連携方法
  list_cooperation_method: [],
  // 蓄電池運転モード
  list_battery_operation_mode: [],
  // 連系線
  list_interconnection: [],
  // 発電所種別
  list_pplant_type: [],
  // 発電所契約パターン
  list_pplant_contract_type: [],
  // 自己託送発電所契約パターン
  list_self_consignment_pplant_contract_type: [],
  // DRサービス種類（ELWA）
  list_elwa_dr_service_type: [],
  // DERタイプ（ELWA)
  list_elwa_der_type: [],
  // PowerTraderデータ種別情報
  list_power_trader_data_kind: [],
  // 契約区分
  list_contract_division: [],
  // 相対取引売買種別情報
  list_bid_kind: [],
  // 相対契約者購入価格計算式
  list_negotiation_price_formula: [],
  // 電力購入価格計算式
  list_pplant_price_formula: [],
  // 自己託送電力購入価格計算式
  list_self_consignment_pplant_price_formula: [],
  // GWパターン
  list_gw_type: [],

  // ユーザー種別（アグリゲータ、アセットオーナー、東芝）
  list_user_type: [
    { "value": USER_TYPE.AGGREGATOR, "text": "アグリゲーター" },
    { "value": USER_TYPE.ASSETSOWNER, "text": "アセットオーナー" },
    { "value": USER_TYPE.GENERATOR, "text": "発電事業者" },
    { "value": USER_TYPE.CALCULATE, "text": "精算担当者" },
    { "value": USER_TYPE.OUTSOURCE, "text": "外部委託運用者" },
    { "value": USER_TYPE.TOSHIBA, "text": "東芝" },
  ],
  // 権限（管理者、運用者、照会者）
  list_role: [
    { "value": ROLE.ADMIN, "text": "管理者" },
    { "value": ROLE.OPERATOR, "text": "運用者" },
    { "value": ROLE.REFERRER, "text": "照会者" },
  ],
  // ワークフロー権限（申請者、承認者）
  list_workflow: [
    { "value": WORKFLOW.APPLICANT, "text": "申請者" },
    { "value": WORKFLOW.APPROVER, "text": "承認者" },
  ],

  // 電源（BG）種別
  list_resource_type_code: [
    { "value":"1", "text":"非調整電源"  },
    { "value":"2", "text":"調整電源"  },
    { "value":"3", "text":"FIT電源1" },
    { "value":"4", "text":"FIT電源2" },
    { "value":"5", "text":"FIT送配1" },
    { "value":"6", "text":"FIT送配2" },
    { "value":"7", "text":"FIT送配3" }
  ],

  // 電圧種別
  list_voltage_type_code: [
    { "value":"1", "text":"特別高圧"  },
    { "value":"2", "text":"高圧"  },
    { "value":"3", "text":"低圧非調整電源" }
  ],
  
  // スポット取引契約種別
  list_spot_trading_contract_type: [
    { "value":"1", "text":"従量制"  },
    { "value":"2", "text":"定額制"  }
  ],

  // 表示タイプ
  list_message_type: [
    { "value":"request,error", "text": "依頼・障害" },
    { "value":"info",           "text": "連絡"},
    { "value":"request",        "text": "依頼" },
    { "value":"warning",        "text": "警告" },
    { "value":"debug" ,         "text": "その他" },
    { "value":"error",          "text": "障害" },
  ],

// 契約管理 契約区分
list_contract_division_code: [
  { "value":"1", "text":"発電契約"  },
  { "value":"2", "text":"小売契約"  },
  { "value":"3", "text":"SaaS"  },
  { "value":"4", "text":"業務委託"  }
],

// 契約管理 契約パターン
list_contract_pattern_code: [
  { "division":"2", "value":"1", "text":"JEPXへ売電"  },
  { "division":"2", "value":"2", "text":"参照価格＋手数料"  },
  { "division":"2", "value":"3", "text":"参照価格＋手数料（上下限キャップ）"  },
  { "division":"2", "value":"4", "text":"固定価格"  },
  { "division":"3", "value":"5", "text":"SaaS"  },
  { "division":"4", "value":"6", "text":"業務委託①（自己託送）"  },
  { "division":"4", "value":"7", "text":"業務委託②（自己託送）"  },
  { "division":"4", "value":"8", "text":"業務委託③（再エネBG）"  },
  { "division":"4", "value":"9", "text":"業務委託④（再エネBG）"  },
  { "division":"4", "value":"10", "text":"業務委託⑤（再エネBG）"  },
  { "division":"4", "value":"11", "text":"業務委託⑥（再エネBG）"  },
  { "division":"4", "value":"12", "text":"業務委託⑦（再エネBG）"  },
  { "division":"4", "value":"13", "text":"業務委託⑧（再エネBG）"  }
],

  // 48コマ
  list_time_code_type:[
    { value: '1', text: '0:00' },
    { value: '2', text: '0:30' },
    { value: '3', text: '1:00' },
    { value: '4', text: '1:30' },
    { value: '5', text: '2:00' },
    { value: '6', text: '2:30' },
    { value: '7', text: '3:00' },
    { value: '8', text: '3:30' },
    { value: '9', text: '4:00' },
    { value: '10', text: '4:30' },
    { value: '11', text: '5:00' },
    { value: '12', text: '5:30' },
    { value: '13', text: '6:00' },
    { value: '14', text: '6:30' },
    { value: '15', text: '7:00' },
    { value: '16', text: '7:30' },
    { value: '17', text: '8:00' },
    { value: '18', text: '8:30' },
    { value: '19', text: '9:00' },
    { value: '20', text: '9:30' },
    { value: '21', text: '10:00' },
    { value: '22', text: '10:30' },
    { value: '23', text: '11:00' },
    { value: '24', text: '11:30' },
    { value: '25', text: '12:00' },
    { value: '26', text: '12:30' },
    { value: '27', text: '13:00' },
    { value: '28', text: '13:30' },
    { value: '29', text: '14:00' },
    { value: '30', text: '14:30' },
    { value: '31', text: '15:00' },
    { value: '32', text: '15:30' },
    { value: '33', text: '16:00' },
    { value: '34', text: '16:30' },
    { value: '35', text: '17:00' },
    { value: '36', text: '17:30' },
    { value: '37', text: '18:00' },
    { value: '38', text: '18:30' },
    { value: '39', text: '19:00' },
    { value: '40', text: '19:30' },
    { value: '41', text: '20:00' },
    { value: '42', text: '20:30' },
    { value: '43', text: '21:00' },
    { value: '44', text: '21:30' },
    { value: '45', text: '22:00' },
    { value: '46', text: '22:30' },
    { value: '47', text: '23:00' },
    { value: '48', text: '23:30' },
  ],
  list_time_codes: [
    {time_code_name : '0:00-0:30', time_code_id: '1'},
    {time_code_name : '0:30-1:00', time_code_id: '2'},
    {time_code_name : '1:00-1:30', time_code_id: '3'},
    {time_code_name : '1:30-2:00', time_code_id: '4'},
    {time_code_name : '2:00-2:30', time_code_id: '5'},
    {time_code_name : '2:30-3:00', time_code_id: '6'},
    {time_code_name : '3:00-3:30', time_code_id: '7'},
    {time_code_name : '3:30-4:00', time_code_id: '8'},
    {time_code_name : '4:00-4:30', time_code_id: '9'},
    {time_code_name : '4:30-5:00', time_code_id: '10'},
    {time_code_name : '5:00-5:30', time_code_id: '11'},
    {time_code_name : '5:30-6:00', time_code_id: '12'},
    {time_code_name : '6:00-6:30', time_code_id: '13'},
    {time_code_name : '6:30-7:00', time_code_id: '14'},
    {time_code_name : '7:00-7:30', time_code_id: '15'},
    {time_code_name : '7:30-8:00', time_code_id: '16'},
    {time_code_name : '8:00-8:30', time_code_id: '17'},
    {time_code_name : '8:30-9:00', time_code_id: '18'},
    {time_code_name : '9:00-9:30', time_code_id: '19'},
    {time_code_name : '9:30-10:00', time_code_id: '20'},
    {time_code_name : '10:00-10:30', time_code_id: '21'},
    {time_code_name : '10:30-11:00', time_code_id: '22'},
    {time_code_name : '11:00-11:30', time_code_id: '23'},
    {time_code_name : '11:30-12:00', time_code_id: '24'},
    {time_code_name : '12:00-12:30', time_code_id: '25'},
    {time_code_name : '12:30-13:00', time_code_id: '26'},
    {time_code_name : '13:00-13:30', time_code_id: '27'},
    {time_code_name : '13:30-14:00', time_code_id: '28'},
    {time_code_name : '14:00-14:30', time_code_id: '29'},
    {time_code_name : '14:30-15:00', time_code_id: '30'},
    {time_code_name : '15:00-15:30', time_code_id: '31'},
    {time_code_name : '15:30-16:00', time_code_id: '32'},
    {time_code_name : '16:00-16:30', time_code_id: '33'},
    {time_code_name : '16:30-17:00', time_code_id: '34'},
    {time_code_name : '17:00-17:30', time_code_id: '35'},
    {time_code_name : '17:30-18:00', time_code_id: '36'},
    {time_code_name : '18:00-18:30', time_code_id: '37'},
    {time_code_name : '18:30-19:00', time_code_id: '38'},
    {time_code_name : '19:00-19:30', time_code_id: '39'},
    {time_code_name : '19:30-20:00', time_code_id: '40'},
    {time_code_name : '20:00-20:30', time_code_id: '41'},
    {time_code_name : '20:30-21:00', time_code_id: '42'},
    {time_code_name : '21:00-21:30', time_code_id: '43'},
    {time_code_name : '21:30-22:00', time_code_id: '44'},
    {time_code_name : '22:00-22:30', time_code_id: '45'},
    {time_code_name : '22:30-23:00', time_code_id: '46'},
    {time_code_name : '23:00-23:30', time_code_id: '47'},
    {time_code_name : '23:30-24:00', time_code_id: '48'}
  ],
  // 月間業務委託料
  list_monthly_outsourcing_price: [
    { text: '電力受給対象年月から1カ月前', value: '1' },
    { text: '電力受給対象年月から2カ月前', value: '2' },
    { text: '電力受給対象年月から3カ月前', value: '3' },
    { text: '電力受給対象年月から4カ月前', value: '4' },
    { text: '電力受給対象年月から5カ月前', value: '5' },
    { text: '電力受給対象年月から6カ月前', value: '6' },
    { text: '電力受給対象年月から7カ月前', value: '7' },
    { text: '電力受給対象年月から8カ月前', value: '8' },
    { text: '電力受給対象年月から9カ月前', value: '9' },
    { text: '電力受給対象年月から10カ月前', value: '10' },
    { text: '電力受給対象年月から11カ月前', value: '11' },
    { text: '電力受給対象年月から12カ月前', value: '12' },
  ],
  // 支払予定日
  list_payment_date: [
    { text: '電力受給対象年月+3か月末', value: '3' },
    { text: '電力受給対象年月+2か月末', value: '2' },
    { text: '電力受給対象年月+1か月末', value: '1' },
  ],
  // 発電予測実施
  list_exec_forecast_flg: [
    { text: '実施しない', value: false },
    { text: '実施する', value: true },
  ],
  //出力抑制補正
  list_output_suppression_flg: [
    { text: '当日計画補正しない', value: false },
    { text: '当日計画補正する', value: true },
  ],
  // 入札パターン
  list_bid_type: [
    { text: '応札型', value: '1' },
    { text: '提示型', value: '2' },
    { text: 'アイスバーグ型', value: '3' },
  ],
  //受電電力値の取得
  list_received_power: [
    { text: '実績値を取得しない', text2: '取得しない', value: false },
    { text: '実績値を取得する', text2: '取得する', value: true },
  ],
  // 予測実施
  list_demand_exec_forecast_flg: [
    { text: '予測しない', value: false },
    { text: '予測する', value: true },
  ],
  // 需給調整市場利用可否
  list_avaliable_sada_market: [
    { text: '利用しない', value: false },
    { text: '利用する', value: true },
  ],
  // 分配方法
  list_distribution_method: [
    { text: '等配分', value: '1' },
    { text: 'メリットオーダー', value: '2' },
  ],
};

const getters = {
  ...createGetters(state)
};

const mutations = {
  ...createMutations(state)
}

const actions = {
  async fetch( ctx, targets ) {
    
    // アグリゲーターの取得
    if ( targets.includes('aggregator') ) {
      const { data } = await service.getAggregator();
      ctx.commit('list_aggregator',  deepClone(data).map( a => {
        a.value = a.aggregator_id;
        a.text = a.aggregator_name;
        return a;
      }) );
    }
    
    // アグリゲーターの取得
    if ( targets.includes('aggreconsign') ) {
      const { data } = await service.getAggregatorConsignment();
      ctx.commit('list_aggregator',  deepClone(data).map( a => {
        a.value = a.aggregator_id;
        a.text = a.aggregator_name;
        return a;
      }) );
    }

    // アグリゲーター(需要拠点)の取得
    if ( targets.includes('aggregatordpoint') ) {
      const { data } = await service.getAggregatorDpoint();
      ctx.commit('list_aggregator',  deepClone(data).map( a => {
        a.value = a.aggregator_id;
        a.text = a.aggregator_name;
        return a;
      }) );
    }
    
    // 他リストの取得
    targets = targets.filter( t => t !== 'aggregator' && t !== 'aggreconsign' && t !== 'aggregatordpoint' );
    // アグリゲータのみ取得する場合、他のリストについて取得の要求を行わない
    if (targets.length > 0) {
      const { data } = await service.get(targets);
    
      Object.keys(data).forEach( key => {
        //ctx.commit(`list_${key}`, deepClone(data[key]));
        if ( key === 'area') {
          // 設置エリア
          ctx.commit(`list_area`, deepClone(data[key]).filter(a => a.install_area_flg).map( a => {
            a.value = a.area_id;
            a.text = a.area_name;
            return a;
          }));

          // 電力エリア
          ctx.commit(`list_energy_area`, deepClone(data[key]).filter(a => a.energy_area_flg).map( a => {
            a.value = a.area_id;
            a.text = padStart(a.area_id, 2, '0') + ':' + a.area_name;
            a.area_name = a.text;
            return a;
          }));

          // 価格エリア
          ctx.commit(`list_price_area`, deepClone(data[key]).filter(a => a.price_area_flg).map( a => {
            a.value = a.area_id;
            a.text = a.area_name;
            return a;
          }));

          // 入札エリア
          ctx.commit(`list_bid_area`, deepClone(data[key]).filter(a => a.bid_area_flg).map( a => {
            a.value = a.area_id;
            a.text = a.area_name;
            return a;
          }));
        } else if ( key === 'prefecture') {
          // 都道府県
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.prefecture_code;
            a.text = a.prefecture_name;
            return a;
          }));
        } else if ( key === 'resource_type') {
          // リソース種別
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.resource_type_code;
            a.text = a.resource_type_name;
            return a;
          }));
        } else if ( key === 'operation_method') {
          // 運用方法
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.operation_method_code;
            a.text = a.operation_method_name;
            return a;
          }));

        } else if ( key === 'gw_pattern') {
          // GWパターン
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.gw_pattern_id;
            a.text = a.gw_pattern_name;
            return a;
          }));
        } else if ( key === 'power_conditioner') {
          // GWパターン
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.power_conditioner_id;
            a.text = a.power_conditioner_name;
            return a;
          }));
        } else if ( key === 'cooperation_method') {
          // 連携方法
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.cooperation_method_id;
            a.text = a.cooperation_method_name;
            return a;
          }));
        } else if ( key === 'battery_operation_mode') {
          // 蓄電池運転モード
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.battery_operation_mode_code;
            a.text = a.battery_operation_mode_name;
            return a;
          }));
        } else if ( key === 'interconnection') {
          // 連系線
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.interconnection_id;
            a.text = a.interconnection_name;
            return a;
          }));
        } else if ( key === 'pplant_type') {
          // 発電所種別
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.pplant_type_code;
            a.text = a.pplant_type_name;
            return a;
          }));
        } else if ( key === 'pplant_contract_type') {
          // 発電所契約パターン
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.pplant_contract_type_code;
            a.text = a.pplant_contract_type_name;
            return a;
          }));
        } else if ( key === 'self_consignment_pplant_contract_type') {
          // 自己託送発電所契約パターン
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.pplant_contract_type_code;
            a.text = a.pplant_contract_type_name;
            return a;
          }));
        } else if ( key === 'elwa_dr_service_type') {
          // DRサービス種類
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.elwa_dr_service_type_code;
            a.text = a.elwa_dr_service_type_name;
            return a;
        }));
        } else if ( key === 'elwa_der_type') {
          // DERタイプ
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.elwa_der_type_code;
            a.text = a.elwa_der_type_name;
            return a;
          }));
        } else if ( key === 'power_trader_data_kind') {
          // PowerTraderデータ種別情報
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.power_trader_data_kind_number;
            a.text = a.power_trader_data_kind_name;
            return a;
          }));
        } else if ( key === 'contract_division') {
          // 契約区分
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.contract_division_code;
            a.text = a.contract_division_name;
            return a;
          }));
        } else if ( key === 'bid_kind') {
          // 相対取引売買種別
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.bid_kind_code;
            a.text = a.bid_kind_name;
            return a;
          }));
        } else if ( key === 'negotiation_price_formula') {
          // 相対契約者購入価格計算式
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.price_formula_code;
            a.text = a.price_formula_name;
            return a;
          }));
        } else if ( key === 'pplant_price_formula') {
          // 電力購入価格計算式
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.price_formula_code;
            a.text = a.price_formula_name;
            return a;
          }));
        } else if ( key === 'self_consignment_pplant_price_formula') {
          // 自己託送電力購入価格計算式
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.price_formula_code;
            a.text = a.price_formula_name;
            return a;
          }));
        } else if ( key === 'gw_type') {
          // GW種別
          ctx.commit(`list_${key}`, deepClone(data[key]).map( a => {
            a.value = a.gw_type_id;
            a.text = a.gw_type_name;
            return a;
          }));
        }
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};