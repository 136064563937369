<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-overflow
    :nudge-top="24"
    min-width="290px"
  >
    <!-- text-field -->
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="disp"
        :label="label"
        append-icon="mdi-calendar-today"
        readonly
        :rules="rules"
        :outlined="outlined"
        :dense="dense"
        :clearable="clearable"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        @click:clear="clear"
      ></v-text-field>
    </template>

    <!-- picker -->
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      :allowed-dates="allowedDates"
      @input="input"
      type="month"
    ></v-date-picker>
  </v-menu>

</template>
<script>

export default {
  name: 'DateTextField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      tyle: Array
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    allowedDates: {
      type: Function,
    },
  },
  data() {
    return {
      menu: false,
      date: '',
    };
  },
  computed: {
    disp () {
      return this.date;
    }
  },
  watch:{
    value(v) {
      if ( this.isEmpty(v) ) this.date = '';
      else this.date = v;
    }
  },
  created (){
    if ( this.isEmpty(this.value) ) this.date = '';
    else this.date = this.value;
  },
  methods: {
    input () {
      this.menu = false;
      this.$emit('input', this.date);
    },
    clear () {
      this.date = '';
      this.$emit('input', this.date);
    },
    isEmpty (v) {
      return v === null || v === '' || v === undefined;
    }
  },
};
</script>