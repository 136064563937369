import api from './api';

const path = '/list';

export default {
  // リスト取得 (アグリゲーター)
  async getAggregator() {
    return await api.get(`${path}/aggregator`);
  },
  // リスト取得 (自己託送アグリゲーター)
  async getAggregatorConsignment() {
    return await api.get(`${path}/aggregatorconsignment`);
  },
  // リスト取得 (需要拠点アグリゲーター)
  async getAggregatorDpoint() {
    return await api.get(`${path}/aggregatordpoint`);
  },
  // リスト取得 (バランシンググループ)
  async getBgroup(params) {
    return await api.get(`${path}/${params.aggregator_id}/bgroup`, { params });
  },
  // リスト取得 (発電所)
  async getPowerPlant(params) {
    return await api.get(`${path}/${params.aggregator_id}/power_plant`);
  },
  // リスト取得 (リソース)
  async getResource(params) {
    return await api.get(`${path}/${params.aggregator_id}/resource`);
  },
  // リスト取得 (エリア(アグリゲータに紐づく))
  async getArea(params) {
    return await api.get(`${path}/${params.aggregator_id}/area`);
  },
  // リスト取得 (ユーザー(アグリゲータに紐づく))
  async getUser(params) {
    return await api.get(`${path}/${params.aggregator_id}/users`, { params });
  },
  // リスト取得 (東芝ユーザー)
  async getToshibaUser(params) {
    return await api.get(`${path}/${params.aggregator_id}/users/toshiba`, { params });
  },
  // リスト取得（その他）
  async get(target) {
    const params = { request: target.join(',') };
    return await api.get(`${path}/`, { params });
  },
  // リスト取得 (地点)
  async getPlace(params) {
    return await api.get(`${path}/place/`, { params });//area_id
  },
  // リスト取得 (自己託送発電グループ)
  async getSelfConsignmentBlancingGroup(params) {
    return await api.get(`${path}/${params.aggregator_id}/bgroup/self_consignment`, { params });
  },
  // リスト取得 (自己託送需要グループ)
  async getSelfConsignmentJGroup(params) {
    return await api.get(`${path}/${params.aggregator_id}/jgroup/self_consignment`, { params });
  },
  // リスト取得 (自己託送発電所)
  async getSelfConsignmentPowerPlant(params) {
    return await api.get(`${path}/${params.aggregator_id}/power_plant/self_consignment`);
  },
  // リスト取得 (自己託送需要地点)
  async getSelfConsignmentDpoint(params) {
      return await api.get(`${path}/${params.aggregator_id}/dpoint/self_consignment`, { params });
  },
  // リスト取得 (予測モデル)
  async getForecastModel(params) {
    return await api.get(`${path}/${params.resource_type}/${params.forecast_flg}/forecast`);
  },
  // リスト取得 (PowerTraderデータ種別情報(相対取引JEPX設定))
  async getPTDataKindJepx(params) {
    return await api.get(`${path}/${params.aggregator_id}/pt_data_kind_jepx`);
  },
  // リスト取得 (PowerTraderデータ種別情報(発電所取引JEPX設定))
  async getPTDataKindJepxPplant(params) {
    return await api.get(`${path}/${params.aggregator_id}/pt_data_kind_jepx_pplant`);
  },
  // リスト取得 (契約)
  async getContract(params) {
    return await api.get(`${path}/${params.aggregator_id}/contract`);
  },
  // リスト取得 (リソース数)
  async getResourceCount(params) {
    return await api.get(`${path}/${params.aggregator_id}/resource_count`, { params });
  },
  // リスト取得 (PowerTraderデータ種別番号)
  async getPowerTraderDataKindNumber(params) {
    return await api.get(`${path}/${params.aggregator_id}/power_trader_data_kind_number`);
  },
  // リスト取得 (需給調整市場利用発電所)
  async getEprxPplant(params) {
    return await api.get(`${path}/${params.aggregator_id}/eprx_pplant`);
  },
}