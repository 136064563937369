import api from './api';

const service = {
  // 相対取引テンプレート取得
  async create() {
    return await api.get(`/files/templates/negotiation_trading_upload`, { responseType: 'blob', dataType: 'binary' });
  },
  
  // 相対取引情報アップロード
  async uploadNegotiation(file, aggregator_id, area_id, isForce) {
    const form = new FormData();
    let payload = { 'is_force': isForce }
    form.append('body', JSON.stringify(payload))
    form.append('file', file);
    return await api.post(`/files/csv/${aggregator_id}/negotiation_trading_upload/${area_id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  // 購入量上下限テンプレート取得
  async createPurchase() {
    return await api.get(`/files/templates/purchase_upper_lower`, { responseType: 'blob', dataType: 'binary' });
  },

  // 購入量上下限アップロード
  async uploadNegotiationPurchase(file, aggregator_id, area_id) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`/files/csv/${aggregator_id}/purchase_upper_lower/${area_id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}

export default service;