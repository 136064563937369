import api from './api';

const service = {
    async searchBGroupList(aggregator_id, params) {
        return await api.get(`/aggregators/${aggregator_id}/bgroup_pplant/mape`, { params: {
            area_id: params.area_id,
            resource_type_id: params.resource_type_id,
            target_date: params.targetDate
        }});
    },
    async searchForecastDataByResource(aggregator_id, resource_id, targetDate, forecast_model) {
        return await api.get(
            `/aggregators/${aggregator_id}/resources/${resource_id}/month/${targetDate}/forecast_model/${forecast_model}/mape`
            );
    },
}

export default service;
