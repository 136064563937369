import api from './api';

const service = {
  async search(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow`);
  },
  async apply(params, apply) {
    return await api.post(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow/create`, apply);
  },
  async cancel(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow/cancel`);
  },
  async approval(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow/approve`);
  },
  async reject(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow/reject`);
  },
  async edit(params) {
    return await api.put(`/aggregators/${params.aggregator_id}/self_consignment/areas/${params.area_id}/dates/${params.target_date}/position_flow/edit`);
  },
}

export default service;