<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-overflow
    :nudge-top="24"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="disp"
        :label="label"
        append-icon="mdi-calendar-today"
        readonly
        :rules="rules"
        :outlined="outlined"
        :dense="dense"
        :clearable="clearable"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click:append="menu = true"
        @click:clear="clear"
      ></v-text-field>
    </template>
    <v-date-picker
      :disabled="readonly || disabled"
      v-model="dates"
      @input="input"
      range
      :min=min
      :max=max
    ></v-date-picker>
  </v-menu>

</template>
<script>

export default {
  name: 'DateRangeTextField',
  inheritAttrs: false,
  props: {
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      tyle: Array
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
    };
  },
  computed: {
    disp () {
      if (this.dates.length === 2) {
        let dt = this.dates.slice().sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        if ( !this.isEmpty(dt[0]) || !this.isEmpty(dt[1]) ) {
          return dt.join(' ~ ');
        }
      }
      return '';
    }
  },
  watch: {
    start() {
      this.dates = [this.start, this.end];
    },
    end() {
      this.dates = [this.start, this.end];
    },
  },
  created (){
    this.dates = [this.start, this.end];
  },
  methods: {
    input () {
      if ( this.dates.length == 2 ) {
        let dt = this.dates.slice().sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        this.menu = false;
        this.$emit('update:start',dt[0]);
        this.$emit('update:end', dt[1]);
      }
    },
    clear () {
      this.dates = [null, null];
      this.$emit('update:start', '');
      this.$emit('update:end', '');
    },
    isEmpty (v) {
      return v === null || v === '' || v === undefined;
    }
  }
};
</script>