import api from './api';

const service = {
    async searchResource(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/imbalance`, { params });
    },
    async updateImbalance(params,request) {
        return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/balancing_groups/${params.bgroup_id}/imbalance`,request);
    },
    async searchMonitoring(aggregator_id) {
        return await api.get(`/aggregators/${aggregator_id}/bid_imbalance/`)
    },
    async updateMonitoring(aggregator_id,request) {
        return await api.put(`/aggregators/${aggregator_id}/bid_imbalance`,  request )
    },
    async searchImbalanceAlert(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/imbalance-threshold`, {params:{ area_id: params.area_id}});
    },
    async updateImbalanceAlert(params) {
        return await api.put(`/aggregators/${params.aggregator_id}/imbalance-threshold?area_id=${params.area_id}`, params);
    },
}

export default service;
