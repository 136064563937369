import api from './api';

const service = {
    async searchResourceConfig(params) {
      return await api.get(`/aggregators/${params.aggregator_id}/resources/config/actual`, { params });
    },
    async downloadResource(params) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/resource_type/${params.resource_type}/actual/`,{ params, responseType:'blob', dataType:'binary' });
    },
    async downloadActualData(params, kind) {
      return await api.get(`/files/csv/aggregators/${params.aggregator_id}/resource_id/${params.resource_id}/kind/${kind}/actual/`,{ params, responseType:'blob', dataType:'binary' });
    },
}

export default service;