import api from './api';

const service = {
  async searchPplant(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/demand_procurement/power_plants/`, { params });
  },
  async createPplant(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/demand_procurement/power_plants`, payload);
  },
  async updatePplant(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/demand_procurement/power_plants/${payload.pplant_id}`, payload);
  },
  async deletePplant(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/demand_procurement/power_plants/${payload.pplant_id}`);
  },
  async inquirePplantResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/demand_procurement/power_plants/${payload.pplant_id}/resources/`);
  },
  async searchPplantResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/demand_procurement/power_plants/${params.pplant_id}/resources/select`, { params });
  },
  async updatePplantResource(payload, aggregator_id, pplant_id) {
    return await api.put(`/aggregators/${aggregator_id}/demand_procurement/power_plants/${pplant_id}/resources/`, payload);
  },
  async searchBgroupResource(params) {
    return await api.get(`/aggregators/${params.aggregator_id}/power_generation_groups/${params.bgroup_id}/resources/select`, { params });
  },
  async updateBgroupResource(payload, aggregator_id, bgroup_id) {
    return await api.put(`/aggregators/${aggregator_id}/power_generation_groups/${bgroup_id}/power_plants/`, payload);
  },

  async searchDgroup(params) {  
    return await api.get(`/aggregators/${params.aggregator_id}/demand_procurement/demand_groups`, { params });
  },
  async createDgroup(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups`, payload);
  },
  async updateDgroup(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}`, payload);
  },
  async deleteDgroup(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}`);
  },

  async inquireDpointResource(payload) {
    return await api.get(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}/demand_points`);
  },
  async createDpoint(payload) {
    return await api.post(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}/demand_points`, payload);
  },
  async updateDpoint(payload) {
    return await api.put(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}/demand_points/${payload.dpoint_id}`, payload);
  },
  async deleteDpoint(payload) {
    return await api.delete(`/aggregators/${payload.aggregator_id}/demand_procurement/demand_groups/${payload.jgroup_id}/demand_points/${payload.dpoint_id}`);
  },
  // 低圧NSC連携情報アップロード
  async uploadLowNscCooperate(pplant_id, file) {
    const form = new FormData();
    form.append('file', file);
    return await api.post(`files/csv/pplant_id/${pplant_id}/low_nsc_cooperate/2`, form,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }, 
  // 低圧NSC連携情報テンプレート取得
  async createTempleteLowNscCooperate() {
    return await api.get(`/files/templates/low_nsc_cooperate/2`);
  },
}

export default service;
