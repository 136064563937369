import auth from './auth';
import list from './list';
import ResourceManage from './ResourceManage';
import ResourceManageContractCondition from './ResourceManageContractCondition';
import ConsignmentResourceManage from './ConsignmentResourceManage';
import ResourceOperation from './ResourceOperation';
import ResourceStatusByArea from './ResourceStatusByArea';
import ResourceStatusByBgroup from './ResourceStatusByBgroup';
import ResourceStatusBySite from './ResourceStatusBySite';
import ResourceStatusByResource from './ResourceStatusByResource';
import ResourceStatusByLowResource from './ResourceStatusByLowResource';
import ResourceStatusByMonitor from './ResourceStatusByMonitor';
import ResourceStatusConfig from './ResourceStatusConfig';
import ForecastMape from './ForecastMape';
import DataRegister from './DataRegister';
import CalculateDataRegister from './CalculateDataRegister';
import NegotiationRegister from './NegotiationRegister';
import PositionFlow from './PositionFlow';
import PvForecast from './PvForecast';
import WpForecast from './WpForecast';
import HpForecast from './HpForecast';
import PriceForecast from './PriceForecast';
import ImbalanceForecast from './ImbalanceForecast';
import DemandForecast from './DemandForecast';
import BatteryPlan from './BatteryPlan';
import SalesPlan from './SalesPlan';
import FitSalesPlan from './FitSalesPlan';
import SpotTrading from './SpotTrading';
import SpotTradingKind from './SpotTradingKind';
import AutoTrading from './AutoTrading';
import SameDayTrading from './SameDayTrading';
import ForecastDataDownload from './ForecastDataDownload';
import BatteryPlanDownload from './BatteryPlanDownload';
import TradingDataDownload from './TradingDataDownload';
import CalculateFileDownload from './CalculateFileDownload';
import ActualDataDownload from './ActualDataDownload';
import LowActualDataDownload from './LowActualDataDownload';
import OperationDataDownload from './OperationDataDownload';
import SalePlanDownload from './SalePlanDownload';
import PmsLinkageDownload from './PmsLinkageDownload';
import SimulationRegister from './SimulationRegister';
import SimulationRequest from './SimulationRequest';
import SimulationResult from './SimulationResult';
import SimulationRequestForecast from './SimulationRequestForecast';
import SimulationResultForecast from './SimulationResultForecast';
import AnalysisReportRequest from './AnalysisReportRequest';
import AnalysisReportResult from './AnalysisReportResult';
import ReportRequestForecast from './ReportRequestForecast';
import DashBoard from './DashBoard';
import UsersManage from './UsersManage';
import ConsignmentPositionFlow from './ConsignmentPositionFlow';
import ConsignmentPvForecast from './ConsignmentPvForecast';
import ConsignmentWpForecast from './ConsignmentWpForecast';
import ConsignmentHpForecast from './ConsignmentHpForecast';
import ConsignmentSpotTrading from './ConsignmentSpotTrading';
import ConsignmentSameDayTrading from './ConsignmentSameDayTrading';
import ConsignmentSalesPlan from './ConsignmentSalesPlan';
import ConsignmentDemandQuantity from './ConsignmentDemandQuantity';
import ConsignmentElectricQuantity from './ConsignmentElectricQuantity';
import ConsignmentResourceStatusBySite from './ConsignmentResourceStatusBySite';
import ConsignmentAutoTrading from './ConsignmentAutoTrading';
import AssumptionSalesRegister from './AssumptionSalesRegister';
import AlgorithmRequest from './AlgorithmRequest';
import MailReport from './MailReport';
import GePlan from './GePlan';
import ForecastProcessing from './ForecastProcessing';
import NegotiationTrading from './NegotiationTrading';
import BalancedMarketRegister from './BalancedMarketRegister';
import DemandProcurePlan from './DemandProcurePlan';
import ProcurementResourceManage from './ProcurementResourceManage';
import PriceCalculateRegister from './PriceCalculateRegister';
import CalculateNotice from './CalculateNotice';
import ReportConfirmDownload from './ReportConfirmDownload';
import ConsignmentNotify from './ConsignmentNotify';
import SuppressionDataDownload from './SuppressionDataDownload';
import AlgorithmResultDownload from './AlgorithmResultDownload';
import EprxTertiary2Forecast from './EprxTertiary2Forecast';
import EprxBidConfig from './EprxBidConfig';
import BatteryOutputControl from './BatteryOutputControl';
import LowResourceOperation from './LowResourceOperation';
import LowResourceOperationUpload from './LowResourceOperationUpload';
import MarketTradingConfig from './MarketTradingConfig';

const repositories = {
  list,
  auth,
  ResourceManage,
  ResourceManageContractCondition,
  ConsignmentResourceManage,
  ResourceOperation,
  ResourceStatusByArea,
  ResourceStatusByBgroup,
  ResourceStatusBySite,
  ResourceStatusByResource,
  ResourceStatusByLowResource,
  ResourceStatusByMonitor,
  ResourceStatusConfig,
  ForecastMape,
  DataRegister,
  CalculateDataRegister,
  PositionFlow,
  PvForecast,
  WpForecast,
  HpForecast,
  PriceForecast,
  ImbalanceForecast,
  DemandForecast,
  BatteryPlan,
  SalesPlan,
  FitSalesPlan,
  SpotTrading,
  SpotTradingKind,
  NegotiationRegister,
  AutoTrading,
  SameDayTrading,
  ForecastDataDownload,
  BatteryPlanDownload,
  TradingDataDownload,
  ActualDataDownload,
  LowActualDataDownload,
  CalculateFileDownload,
  OperationDataDownload,
  SalePlanDownload,
  PmsLinkageDownload,
  SimulationRegister,
  SimulationRequest,
  SimulationResult,
  SimulationRequestForecast,
  SimulationResultForecast,
  AnalysisReportRequest,
  AnalysisReportResult,
  ReportRequestForecast,
  DashBoard,
  UsersManage,
  ConsignmentPositionFlow,
  ConsignmentPvForecast,
  ConsignmentWpForecast,
  ConsignmentHpForecast,
  ConsignmentSpotTrading,
  ConsignmentSameDayTrading,
  ConsignmentSalesPlan,
  ConsignmentDemandQuantity,
  ConsignmentElectricQuantity,
  ConsignmentResourceStatusBySite,
  ConsignmentAutoTrading,
  AssumptionSalesRegister,
  AlgorithmRequest,
  MailReport,
  GePlan,
  ForecastProcessing,
  NegotiationTrading,
  BalancedMarketRegister,
  DemandProcurePlan,
  ProcurementResourceManage,
  PriceCalculateRegister,
  CalculateNotice,
  ReportConfirmDownload,
  ConsignmentNotify,
  SuppressionDataDownload,
  AlgorithmResultDownload,
  EprxTertiary2Forecast,
  EprxBidConfig,
  BatteryOutputControl,
  LowResourceOperation,
  LowResourceOperationUpload,
  MarketTradingConfig,
}

export default {
  get: name => repositories[name],
}