import api from './api';

const service = {
    async searchSubmissionSalesPlan(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan`);
    },
    async updateSalesPlan(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/making`);
    },
    async DownloadSalesPlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan`)
    },
    async updateSubmissionOctto(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/upload`)
    },
    async searchReceptionSalesPlan(aggregator_id,params) {
        const baseUrl = `/aggregators/${aggregator_id}/electric_power/trading/plan/acceptance`;
        const paramsUrl = Object.entries(params).map((e) => {
            let key = e[0];
            let value = e[1];
            return `${key}=${value}`;
          }).join("&");
        const url = baseUrl + '?' + paramsUrl;
        return await api.get(url);
    },
    async searchAutoSubmissionConfig(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/electric_power/trading/plan/automatic_submission`)
    },
    async updateAutoSubmissionConfig(params,query){
        return await api.put(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/electric_power/trading/plan/automatic_submission?occto_auto_flag=${query.occto_auto_flag}&occto_weeks_auto_flag=${query.occto_weeks_auto_flag}&occto_2days_later_auto_flag=${query.occto_2days_later_auto_flag}` )
    },
    async searchSubmissionSalesPlanWeek(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/weeks_plan`);
    },
    async updateSalesPlanWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/weeks_making`);
    },
    async DownloadSalesPlanXmlWeek(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/weeks_plan`)
    },
    async updateSubmissionOcttoWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/weeks_upload`)
    },
    async searchSubmissionSalesPlanTwoDaysLater(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/two_days_later_plan`);
    },
    async updateSalesPlanTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/two_days_later_making`);
    },
    async DownloadSalesPlanXmlTwoDaysLater(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/two_days_later_plan`)
    },
    async updateSubmissionOcttoTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/electric_power/trading/plan/two_days_later_upload`)
    },
}

export default service;
