import api from './api';

const service = {

    /**
     * システム運用データダウンロード
     * @param {string} params.target_month YYYY-MM 
     * @param {string} params.file_name 
     * @returns 
     */
    async getSystemOperation(params) {
        return await api.get(`/files/csv/operation_data/all/system_operation`, { params, responseType:'blob', dataType:'binary' });
    },

    /**
     * 画面アクセスログダウンロード
     * @param {*} aggregator_id 
     * @param {string} params.target_month YYYY-MM 
     * @param {string} params.file_name 
     * @returns 
     */
    async getAccessLog(aggregator_id, params) {
        return await api.get(`/files/csv/operation_data/aggregators/${aggregator_id}/access_log`, { params, responseType:'blob', dataType:'binary' });
    },
}

export default service;
